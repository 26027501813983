import React, {Component} from 'react';

class BlankLayout extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <main style={{overflow : "inherit"}}>
                {this.props.children}
            </main>
        );
    }
}

export default BlankLayout;