import Axios from "axios";
import {baseUrl} from "../../configs/requestConfig";
import Cookies from "universal-cookie";

const initialHeaders = {}
export default async function PostRequest(url, data, header = initialHeaders, locale = false, callbackProgress = () => {
}) {
    const cookies = new Cookies();
    if (locale)
        header['locale'] = cookies.get('locale');
    else
        header['locale'] = cookies.get('language');
    const config = {
        headers: header,
        onUploadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
                callbackProgress(progressEvent)
            }
        },
        withCredentials: true
    }
    return await Axios.post(baseUrl + url, data, config);
}
